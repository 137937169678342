import { FC } from 'react';

import { Input } from 'reactstrap';

import { SELECT_FIELD_TYPE_RADIO } from 'constants/editors';
import { useAssignmentFieldColor } from 'hooks/signNow';
import { ICustomElement, SelectFieldOptionsType, SelectUpdateEventHandler } from 'types/Editor';
import { SignerType } from 'types/SendDocuments';
import { createNameConstant } from 'utils/createNameConstant';
import { getCheckboxPrintClass, getInputTypeByViewMode } from 'utils/Fields/checkboxRadioHelpers';

interface IRadioSelectElementProps {
  element: Partial<ICustomElement>;
  viewMode: SelectFieldOptionsType;
  onChangeRadioOption: SelectUpdateEventHandler;
  isReadOnlyMode: boolean;
  className?: string;
}

const RadioSelectElement: FC<IRadioSelectElementProps> = ({
  element,
  onChangeRadioOption,
  isReadOnlyMode,
  className = 'doc',
  viewMode = SELECT_FIELD_TYPE_RADIO,
}) => {
  const assignmentColor = useAssignmentFieldColor(
    element.assignment ?? SignerType.RECIPIENT,
    isReadOnlyMode,
  );

  return (
    <>
      {
        element.options?.map(({ id, label, checked, isSelectOtherOption }) => (
          <span key={id} className={`${className}-radio-select__label`}>
            <Input
              type={getInputTypeByViewMode(viewMode)}
              name={`${className}-tab-${createNameConstant(element.name || '')}-${element.key}`}
              value={label}
              disabled={isReadOnlyMode}
              data-id={id}
              data-field="true"
              data-is-other-option={isSelectOtherOption}
              checked={checked ?? false}
              onChange={onChangeRadioOption}
              className={`radio-select-${element.assignment} ${className}-radio-select__input ${
                getCheckboxPrintClass(element.selectFieldType)
              }`}
              style={{ borderColor: assignmentColor }}
              tabIndex={isReadOnlyMode ? -1 : 0}
            />
            <span className={`${className}-radio-select__text ms-2`}>
              {label}
            </span>
          </span>
        ))
      }
      <span />
    </>
  );
};

export default RadioSelectElement;