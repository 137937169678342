import { Descendant, Element as SlateElement } from 'slate';

import {
  ATTACHMENT_FIELD,
  CHECKBOX_FIELD,
  DATE_FIELD,
  RECIPIENT_ASSIGNMENT,
  SELECT_FIELD,
  SELECT_FIELD_TYPE_CHECKBOX,
  SUBTYPE_EMAIL,
  SUBTYPE_FIRST_NAME,
  SUBTYPE_LAST_NAME,
  SUBTYPE_PHONE_MOBILE,
  TEXT_FIELD,
  TEXTAREA_FIELD,
  UNDERLINE_SYMBOL,
  WHITE_SPACE,
} from 'constants/editors';
import {
  DOCUMENT_COOKIE_NAME,
  EMAIL_REGEX,
  FORMAT_GROUP_NAME,
  GROUP_NAME_MAX_LEN,
  LOCALSTORAGE_NAME,
  MAX_USERNAME_LENGTH,
  MIN_USERNAME_LENGTH,
  NUMBERS_REGEX,
  OUT_OF_LIMIT_GROUP_NAME,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PHONE_NUMBER_REGEX,
  REQUIRED_GROUP_NAME,
  SECTIONS_TITLE_MAX_LEN,
  SENDGRID_API_KEY_LENGTH,
  SENDGRID_SETTINGS_FIELDS_NAMES,
  TEXT_AND_NUMBERS_WITH_SPACE_REGEX,
  TEXT_WITH_SPACE_REGEX,
  TEXTAREA_MAX_LEN,
  USER_EMAIL_MAX_LEN,
  USER_NAMES_MAX_LEN,
  USER_NAMES_REGEX,
  WEBSITE_URL_REGEX,
} from 'constants/validation';
import { setPublicFieldValidation } from 'store/actions/editorSlate';
import { ICustomElement, SelectFieldOptionsType, SelectFieldOptionType } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { ITemplateSection } from 'types/redux';
import { IUserManagementFields } from 'types/userProfile';
import {
  ICurrentFieldsGetter,
  IField,
  IFieldValidation,
  IValidated,
  RequiredFieldArrayHasErrorType,
  ValidateFieldHelperType,
} from 'types/validation';
import { filterFormFieldsFromContent } from 'utils/editorFieldHelpers';
import { getIsRadioOrCheckbox } from 'utils/Fields/checkboxRadioHelpers';
import { parseJSON } from 'utils/parseJSON';
import { filterSortedFormFieldsFromJSONObject } from 'utils/pdfTemplatesHelpers';

export const isFieldRequiredValid = (value: string): boolean => Boolean(value.trim());

export const createArrayForValidation = (
  values: { [key: string]: any },
  names: { [key: string]: string },
  required: boolean = true,
) => {
  const arrayNames = Object.keys(names);
  return arrayNames.map((key) => ({
    titleField: names[key],
    nameField: key,
    valueField: values[key],
    required,
  }));
};

const validateSubtypeValue = (name: string, value: string, testRegex: RegExp, maxLength: number = 0): string => {
  if (maxLength > 0 && value.length > maxLength) {
    return `${name}: Maximum length can be equal ${maxLength} characters: "${value}"`;
  }
  if (!testRegex.test(value)) {
    return `${name}: Incorrect field value: "${value}"`;
  }
  return '';
};

const validateCustomSubtypes = (
  subtypeKey: string,
  value: string,
  name: string = 'Field',
  isRequired = false,
): string => {
  if (isRequired && !isFieldRequiredValid(value)) {
    return `${name} is a required field`;
  }
  if (isFieldRequiredValid(value)) {
    if (subtypeKey.startsWith('name__')) {
      return validateSubtypeValue(name, value, USER_NAMES_REGEX, USER_NAMES_MAX_LEN);
    }
    if (subtypeKey.startsWith('email__')) {
      return validateSubtypeValue(name, value, EMAIL_REGEX, USER_EMAIL_MAX_LEN);
    }
    if (subtypeKey.startsWith('website__')) {
      return validateSubtypeValue(name, value, WEBSITE_URL_REGEX);
    }
  }

  return '';
};

export const validateFieldSubtype = (field: IField): string => {
  const fieldTitle = field.title || field.name;
  let message = '';

  if (field?.type && field.type === TEXT_FIELD) {
    switch (field.subtype) {
      case SUBTYPE_EMAIL:
        message = validateSubtypeValue(fieldTitle, field.value, EMAIL_REGEX, USER_EMAIL_MAX_LEN);
        break;
      case SUBTYPE_FIRST_NAME:
      case SUBTYPE_LAST_NAME:
        message = validateSubtypeValue(fieldTitle, field.value, USER_NAMES_REGEX, MAX_USERNAME_LENGTH);
        break;
      default:
        message = validateCustomSubtypes(field.subtype || '', field.value, fieldTitle, field.required);
        break;
    }
  }
  return message;
};

export const validateFieldWithSubtype = (name: string, value: string): [boolean, string] => {
  let message = '';
  switch (name.toLowerCase()) {
    case SUBTYPE_EMAIL:
      message = validateSubtypeValue(name, value, EMAIL_REGEX, USER_EMAIL_MAX_LEN);
      break;
    case SUBTYPE_FIRST_NAME:
    case SUBTYPE_LAST_NAME:
      message = validateSubtypeValue(name, value, USER_NAMES_REGEX, USER_NAMES_MAX_LEN);
      break;
    default:
      message = validateCustomSubtypes(name, value);
      break;
  }
  if (value.trim() === '') {
    return [true, `${name} is a required field`];
  }
  return [!!message.length, message];
};

export const validateFormField = (
  field: IField & {
    requiredField?: boolean,
    validationText?: string,
    options?: SelectFieldOptionType[],
    selectFieldType?: SelectFieldOptionsType,
  },
): string => {
  const fieldValue = (field.value === null) ? '' : field.value;
  const valueIsNotEmpty = isFieldRequiredValid(String(fieldValue));
  const nameField = field.name;
  const titleField = (field.title || nameField)
    .replaceAll(UNDERLINE_SYMBOL, WHITE_SPACE)
    .toLowerCase()
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

  const minLengthValidationFieldNames = ['zip', 'address', 'name', 'city', 'first_name', 'last_name', 'email'];
  const maxLengthValidationFieldNames = [
    ...minLengthValidationFieldNames,
    'template_name',
    'document_name',
    'form_name',
  ];
  const textAndNumbersWithSpacesValidationFieldNames = ['address', 'name'];
  const textWithSpacesValidationFieldNames = ['city'];
  const userNameValidationFieldsNames = ['first_name', 'last_name'];
  const numbersValidationFieldNames = ['zip'];

  if ((field.required || field.requiredField) && field.type === SELECT_FIELD) {
    const isChecked = field.selectFieldType && getIsRadioOrCheckbox(field.selectFieldType)
      ? field?.options?.some((option) => option.checked) || false
      : fieldValue.trim().length;
    return isChecked
      ? ''
      : `Field ${titleField} is required`;
  }

  if (field.required || field.requiredField) {
    if (field.type === CHECKBOX_FIELD) {
      const checkboxChecked = field.checked;
      if (!checkboxChecked) {
        return field.validationText ? field.validationText : `Field ${titleField} is required`;
      }
    } else if (field?.type === ATTACHMENT_FIELD) {
      const attachedCount = field?.properties?.count || 0;
      if (!attachedCount) {
        return 'Please upload attachment here';
      }
    } else if (!valueIsNotEmpty) {
      if (field.validationText) {
        return field.validationText;
      }
      return `Field ${titleField} is required`;
    }
  }

  if (
    nameField === SENDGRID_SETTINGS_FIELDS_NAMES.sendgridApiKey
    && fieldValue !== ''
    && fieldValue.length !== SENDGRID_API_KEY_LENGTH
  ) {
    return `
      ${titleField} default length is ${SENDGRID_API_KEY_LENGTH} characters. Actual length is ${fieldValue.length}
    `;
  }

  if (
    [SENDGRID_SETTINGS_FIELDS_NAMES.sendgridEmail.toLowerCase(), 'email'].includes(nameField.toLowerCase())
    && fieldValue !== ''
    && !EMAIL_REGEX.test(fieldValue.trim())
  ) {
    return `${titleField} is invalid`;
  }

  if (field?.type && field.type === TEXTAREA_FIELD && fieldValue.length > TEXTAREA_MAX_LEN) {
    return `${titleField} maximum length can be equal ${TEXTAREA_MAX_LEN} characters`;
  }
  if (field?.subtype) {
    const message = validateFieldSubtype({ ...field, value: fieldValue, title: titleField });
    if (message) {
      return message;
    }
  }

  if (nameField === 'api_vars' && valueIsNotEmpty) {
    const { error } = parseJSON(String(fieldValue.trim()));
    return String(error || '');
  }

  if (
    textAndNumbersWithSpacesValidationFieldNames.includes(nameField)
    && !TEXT_AND_NUMBERS_WITH_SPACE_REGEX.test(field.value.trim())
  ) {
    return `The ${titleField} is invalid. Can include character symbols, numbers, and \`'":.-&`;
  }

  if (String(fieldValue).includes(LOCALSTORAGE_NAME) || String(fieldValue).includes(DOCUMENT_COOKIE_NAME)) {
    return `Substrings '${LOCALSTORAGE_NAME}' and '${DOCUMENT_COOKIE_NAME}' are forbidden`;
  }

  if (
    userNameValidationFieldsNames.includes(nameField)
    && !USER_NAMES_REGEX.test(field.value.trim())
  ) {
    return `The ${titleField} is invalid`;
  }

  if (
    numbersValidationFieldNames.includes(nameField)
    && !NUMBERS_REGEX.test(field.value)
  ) {
    return `The ${titleField} can include only numeric characters`;
  }

  if (
    textWithSpacesValidationFieldNames.includes(nameField)
    && !TEXT_WITH_SPACE_REGEX.test(field.value.trim())
  ) {
    return `The ${titleField} can include only symbol characters and \`'".-&`;
  }

  if (
    minLengthValidationFieldNames.includes(nameField)
    && String(field.value).trim().length < MIN_USERNAME_LENGTH
  ) {
    return `The ${titleField} shorter than min length ${MIN_USERNAME_LENGTH}.`;
  }

  if (
    maxLengthValidationFieldNames.includes(nameField)
    && String(field.value).trim().length > MAX_USERNAME_LENGTH
  ) {
    return `The ${titleField} longer than maximum length ${MAX_USERNAME_LENGTH}.`;
  }

  return '';
};

export const validationForm = (fields: IFieldValidation[]): IValidated => {
  let validationFields = {};
  let isError: boolean = false;

  fields.forEach(({ titleField, nameField, valueField, required }) => {
    const errorMessage = validateFormField({
      title: titleField,
      name: nameField,
      value: valueField,
      required,
    });
    if (errorMessage) {
      isError = true;
    }

    validationFields = {
      ...validationFields,
      [nameField]: errorMessage || null,
    };
  });

  return {
    validationFields,
    isError,
  };
};

export const validatePublicForm = (fields: (Partial<SlateElement> | PDFFieldType)[]): IValidated => {
  let validationFields = {};
  let fieldsPositions = {};
  let isError: boolean = false;

  fields.forEach((field: Partial<SlateElement> | PDFFieldType, index: number) => {
    const {
      key,
      name,
      fieldName,
      shortName,
      type,
      subtype = '',
      value = '',
      requiredField = false,
      validationText = '',
      properties = null,
    } = field;
    const keyString = String(key);
    const customText = validationText.trim();

    if (keyString.length === 0) {
      isError = true;
      return {
        validationFields,
        fieldsPositions,
        isError,
      };
    }

    const errorMessage = validateFormField({
      title: shortName || fieldName || name,
      name: name || keyString,
      value,
      required: requiredField,
      type,
      subtype,
      properties,
      ...(field.options && { options: field.options }),
      ...(field.selectFieldType && { selectFieldType: field.selectFieldType }),
      ...(field.checked && { checked: field.checked }),
    });

    if (errorMessage) {
      isError = true;
    }

    const message = (isError && errorMessage && customText) ? customText : errorMessage;

    validationFields = {
      ...validationFields,
      [keyString]: message || null,
    };

    fieldsPositions = {
      ...fieldsPositions,
      [keyString]: index,
    };
  });

  return {
    validationFields,
    fieldsPositions,
    isError,
  };
};

export const getCurrentFields = ({
  currentSection,
  content,
  pdfTemplateFields,
  currentDocAssignment,
  isPDFDocument,
  isFormBuilder,
  isDocumentAndFormBuilder,
}: ICurrentFieldsGetter): PDFFieldType[] | Partial<ICustomElement>[] => {
  if (isPDFDocument && isDocumentAndFormBuilder) {
    // Find and replace date fields if they have option 'Todays Date' checked.
    // Only for PDF documents and document and form builder view.
    const resultFields: PDFFieldType[] = [];
    const currentSectionFields = (currentSection?.fields || []).filter(
      (field) => field.assignment === currentDocAssignment,
    );
    currentSectionFields.forEach((fieldInCurrentSection) => {
      if (fieldInCurrentSection.type === DATE_FIELD && fieldInCurrentSection.isTodaysDate) {
        const dateField = Object.values(pdfTemplateFields ?? {}).find(
          (localField) => localField.key === fieldInCurrentSection.key,
        );
        if (dateField) {
          resultFields.push(dateField);
        }
      } else {
        resultFields.push(fieldInCurrentSection as PDFFieldType);
      }
    });
    return resultFields;
  }

  if (isFormBuilder) {
    return (currentSection?.fields || []).filter((field) => field.assignment === currentDocAssignment);
  }

  const currentSectionContent: Descendant[] = (content && !isPDFDocument)
    ? content.filter((el: Descendant) => (currentSection ? el.key === currentSection?.key : true))
    : [];

  return isPDFDocument
    ? filterSortedFormFieldsFromJSONObject(pdfTemplateFields, true, currentDocAssignment)
    : filterFormFieldsFromContent(currentSectionContent, currentDocAssignment);
};

export const errorSectionsName = (sections: ITemplateSection[], checkMaxLength: boolean = false): boolean => {
  const emptyNames = sections.filter((section: ITemplateSection) => (
    section.name.trim() === ''
    || (
      checkMaxLength ? section.name.length > SECTIONS_TITLE_MAX_LEN : false
    )
  ));
  return Boolean(emptyNames.length);
};

export const isSectionNameHasError = (value: string): boolean => (
  value.trim() === '' || value.trim().length > SECTIONS_TITLE_MAX_LEN
);

export const getSectionNameErrorText = (sectionName: string): string => (
  `Section Name ${sectionName.length === 0 ? 'is required' : 'max length reached'}`
);

export const isSurroundedByEmptySpaces = (word: string): boolean => (
  word.startsWith(' ') || word.endsWith(' ')
);

export const isPasswordValidInLength = (password: string): boolean => (
  password.length >= PASSWORD_MIN_LENGTH && password.length <= PASSWORD_MAX_LENGTH
);

export const modalFormValidation = ({ required, value, label, key }: IUserManagementFields): string[] => {
  const errorsArray: string[] = [];
  if (required && value === '') {
    errorsArray.push(`${label} is required`);
  }
  if (key === SUBTYPE_LAST_NAME || key === SUBTYPE_FIRST_NAME) {
    if (value.length > MAX_USERNAME_LENGTH) {
      errorsArray.push(`Max length of ${label} ${MAX_USERNAME_LENGTH}`);
    }
    if (!USER_NAMES_REGEX.test(value.trim())) {
      errorsArray.push(`Not a valid ${label}`);
    }
  }
  if (key === SUBTYPE_EMAIL) {
    if (value.length > MAX_USERNAME_LENGTH) {
      errorsArray.push(`Max length of ${label} ${MAX_USERNAME_LENGTH}`);
    }
    if (!EMAIL_REGEX.test(value.trim())) {
      errorsArray.push('Not a valid Email address');
    }
  }
  if (key === SUBTYPE_PHONE_MOBILE && !PHONE_NUMBER_REGEX.test(value.trim())) {
    errorsArray.push('Please enter a valid cell phone number');
  }
  return errorsArray.length ? errorsArray : [''];
};

export const validateGroupName = (groupName: string): string => {
  if (groupName.length > GROUP_NAME_MAX_LEN) {
    return OUT_OF_LIMIT_GROUP_NAME;
  }

  if (!groupName.trim().length) {
    return REQUIRED_GROUP_NAME;
  }

  if (isSurroundedByEmptySpaces(groupName)) {
    return FORMAT_GROUP_NAME;
  }

  return '';
};

export const validateFieldHelper: ValidateFieldHelperType = ({
  field,
  prevValue,
  value,
  dispatch,
}) => {
  const fieldValidationInfo: string = validateFormField({
    ...field,
    value,
  } as IField);

  if ((prevValue === '' && value) || value === '') {
    dispatch(setPublicFieldValidation({
      fieldKey: field.key || 0,
      validationMessage: fieldValidationInfo,
    }));
  }
};

const isEmptyPdfCheckboxField = (field: Partial<ICustomElement>) => (
  field.type === CHECKBOX_FIELD && field.requiredField && !field.checked
);

export const isRequiredFieldArrayHasError = ({
  contentData,
  assignment = RECIPIENT_ASSIGNMENT,
  isPDF = false,
}: RequiredFieldArrayHasErrorType) => {
  const fieldsData = isPDF
    ? { pdfTemplateFields: contentData as PDFFieldType[] }
    : { content: contentData as Descendant[] };

  const documentsFields: Partial<ICustomElement>[] = getCurrentFields({
    currentDocAssignment: assignment,
    isPDFDocument: isPDF,
    ...fieldsData,
  });

  if (documentsFields.length) {
    if (
      documentsFields.some((field) => field.type === SELECT_FIELD
        && field.selectFieldType === SELECT_FIELD_TYPE_CHECKBOX
        && field.requiredField
        && field.options?.every((option) => !option.checked))
    ) {
      return true;
    }

    const invalidRequiredFields = documentsFields.filter(
      (field) => field.selectFieldType !== SELECT_FIELD_TYPE_CHECKBOX
        && field.requiredField
        && !isFieldRequiredValid(field.value || '')
        && isEmptyPdfCheckboxField(field),
    );
    return invalidRequiredFields.length > 0;
  }
  return false;
};