import { FC } from 'react';

import { IRenderElementProps } from 'components/Editor/components/RenderElement';
import SlateFieldFiller from 'components/Editor/components/SlateFieldFiller';
import { SELECT_FIELD, SELECT_FIELD_TYPE_CHECKBOX, SELECT_FIELD_TYPE_RADIO } from 'constants/editors';
import { useAssignmentFieldColor } from 'hooks/signNow';
import { ICustomElement, LABEL_CONFIGURATION } from 'types/Editor';
import { SignerType } from 'types/SendDocuments';
import { createFieldName, labelConfigurationHandler } from 'utils/editorFieldHelpers';

interface IFieldWrapper {
  assignment: IRenderElementProps['element']['assignment'];
  fieldChildren: IRenderElementProps['children'];
  isReadOnly?: boolean;
  labelConfiguration?: LABEL_CONFIGURATION;
  element?: ICustomElement;
  isPublicPage?: boolean;
}

const FieldWrapper: FC<IFieldWrapper> = ({
  assignment,
  children,
  fieldChildren,
  isReadOnly = false,
  labelConfiguration = LABEL_CONFIGURATION.LEFT,
  element,
  isPublicPage = false,
}) => {
  const {
    isRightLabel,
    isLegalLabel,
    isLeftLabel,
    isNoneLabel,
  } = labelConfigurationHandler(labelConfiguration);

  const assignmentColor = useAssignmentFieldColor(
    assignment ?? SignerType.RECIPIENT,
    isReadOnly,
  );

  const isCheckboxOrRadio = element?.type === SELECT_FIELD
    && [SELECT_FIELD_TYPE_RADIO, SELECT_FIELD_TYPE_CHECKBOX].includes(element?.selectFieldType ?? '');

  return (
    <>
      {
        isLeftLabel
        && (
          <span
            className={`label-text-field-editable label-text-field-editable__after ${isCheckboxOrRadio ? 'me-2' : ''}`}
            style={{ color: assignmentColor, fontSize: element?.fontSize }}
          >
            {
              element && isPublicPage
                ? createFieldName(element.children)
                : fieldChildren
            }
          </span>
        )
      }
      {
        isNoneLabel
        && (
          <span
            contentEditable={false}
            style={{ userSelect: 'none', fontSize: element?.fontSize }}
            className="d-none"
          >
            {
              element && isPublicPage
                ? createFieldName(element.children)
                : fieldChildren
            }
          </span>
        )
      }
      {children}
      {
        (isRightLabel || isLegalLabel)
        && (
          <>
            {isLegalLabel && (
              <span
                style={{ userSelect: 'none', fontSize: element?.fontSize }}
                contentEditable={false}
              >
                (&quot;
              </span>
            )}
            <span
              className="label-text-field-editable"
              style={{ color: assignmentColor, fontSize: element?.fontSize }}
            >
              {
                element && isPublicPage
                  ? createFieldName(element.children)
                  : fieldChildren
              }
            </span>
            {isLegalLabel && (
              <span
                style={{ userSelect: 'none', fontSize: element?.fontSize }}
                contentEditable={false}
              >
                &quot;)
              </span>
            )}
          </>
        )
      }
      {!isLeftLabel && <SlateFieldFiller inline />}
    </>
  );
};

export default FieldWrapper;