import { FC, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { CellMeasurerCache } from 'react-virtualized';

import { Autocomplete, Checkbox, Input, TextFieldProps } from '@mui/material';

import AttachmentDropzone from 'components/AttachmentDropzone';
import DateCalendar from 'components/Base/DateCalendar';
import RadioSelectElement from 'components/Editor/components/Fields/RadioSelectElement';
import FieldName from 'components/Editor/FormView/Fields/FieldName';
import FormBuilderDescription from 'components/FormBuilder/FormBuilderDescription';
import FormBuilderFieldActions from 'components/FormBuilder/FormBuilderFieldActions';
import {
  StyledInputField,
  StyledTextField,
} from 'components/FormBuilder/StyledFields';
import ElementSortButton from 'components/FormConstructor/Buttons/ElementSortButton';
import { DEFAULT_DATE_FORMAT } from 'constants/dateField';
import {
  ATTACHMENT_FIELD,
  CHECKBOX_FIELD,
  DATE_FIELD,
  FORM_BUILDER_PARAGRAPH,
  FORM_TEXTAREA_ROWS_COUNT,
  QUESTION_ANSWER_STRING,
  QUESTION_FIELD,
  SELECT_FIELD,
  SIGNING_FIELD,
  TEXT_FIELD,
  TEXTAREA_FIELD,
} from 'constants/editors';
import { FORM_TYPE } from 'constants/general';
import { TEXTAREA_MAX_LEN } from 'constants/validation';
import { useAssignmentFieldColor } from 'hooks/signNow';
import { updateParagraphValue } from 'store/actions/userData';
import { SignatureIcon } from 'svg/FieldsToolbarsIcons';
import { ICustomElement, SelectFieldOptionsType } from 'types/Editor';
import { FormBuilderType } from 'types/FormBuilder';
import { SignerType } from 'types/SendDocuments';
import { getIsRadioOrCheckbox } from 'utils/Fields/checkboxRadioHelpers';
import { getElementSize } from 'utils/htmlHelpers';
import getFieldName from 'utils/PublicPage/getFieldName';

interface IFormBuilderFieldView {
  index: number;
  width: number;
  field: Partial<ICustomElement>;
  onClick: (selectedFieldKey: number) => void;
  sectionKey: number;
  formBuilderType: FormBuilderType;
  cache: CellMeasurerCache;
  measure: () => void;
  onDeleteParagraphField?: (fieldKey: number) => void;
}

const FormBuilderFieldView: FC<IFormBuilderFieldView> = ({
  index,
  width,
  field,
  onClick,
  sectionKey,
  formBuilderType,
  cache,
  measure,
  onDeleteParagraphField = () => null,
}) => {
  const generalFieldParameters = {
    name: 'value',
    required: field.requiredField,
    invalid: '',
    placeholder: field.helpText,
    readOnly: true,
    disabled: true,
    label: '',
    fullWidth: true,
    onClick: () => onClick(field.key || 0),
  };

  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (fieldRef.current !== null) {
        const height = getElementSize(fieldRef.current, ['height']);
        cache.set(index, 1, width, height);
        measure();
      }
    }, 0);
  }, [fieldRef, index, width, field.position, cache, measure]);

  const dispatch = useDispatch();

  const renderTextField = () => (
    <StyledInputField
      type="text"
      value=""
      onChange={() => null}
      {...generalFieldParameters}
      className="editor-field-view-wrapper"
      inputProps={{
        className: 'editor-field-view-input',
      }}
    />
  );

  const dateInputComponent = ({ InputProps, inputProps, inputRef, label }: TextFieldProps) => {
    const inputParams = {
      inputProps: { ...inputProps, className: 'editor-field-view-input' },
      onClick: () => onClick(field.key || 0),
      ref: inputRef,
      label,
      ...InputProps,
    };
    return (
      <StyledInputField
        {...inputParams}
        autoComplete="off"
        size="small"
        fullWidth
        required={false}
        error={false}
        className="editor-field-view-wrapper"
      />
    );
  };

  const renderDateField = () => (
    <DateCalendar
      fieldValue=""
      dateMask={field.dateMask ?? DEFAULT_DATE_FORMAT}
      isReadOnlyMode
      onChangeDateValue={() => null}
      showAdornment
      inputComponent={dateInputComponent}
      {...generalFieldParameters}
    />
  );

  const renderTextArea = () => (
    <StyledInputField
      multiline
      className="editor-field-view-wrapper"
      type="textarea"
      value=""
      error={false}
      onBlur={() => null}
      onChange={() => null}
      rows={FORM_TEXTAREA_ROWS_COUNT}
      inputProps={{ maxLength: TEXTAREA_MAX_LEN }}
      {...generalFieldParameters}
    />
  );

  const createSelectField = () => (
    <Autocomplete
      className="select-field-autocomplete"
      disablePortal
      options={[]}
      getOptionLabel={(option) => option || ''}
      blurOnSelect
      isOptionEqualToValue={(option, value) => (
        option === value as string
      )}
      value=""
      onChange={() => null}
      noOptionsText=""
      {...generalFieldParameters}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          onClick={() => onClick(field.key || 0)}
        />
      )}
    />
  );

  const renderSigningField = () => {
    const fieldContent = (
      <>
        <span className="signature-icon my-3"><SignatureIcon /></span>
        <span className="field-help-text signature-help-text m-2">Please sign here</span>
      </>
    );

    const variativeClasses = field.value
      ? 'signature-field-button button signature-field-button-public'
      : 'align-items-center justify-content-center';
    const defaultValueParam = field.value ? {} : { defaultValue: field.value };

    return (
      <div
        className="w-100 text-center signature-field-wrapper"
        onClick={() => onClick(field.key || 0)}
        tabIndex={0}
        role="button"
      >
        <div className="signature-field" data-empty={Boolean(!field.value)}>
          <div
            className={`w-100 d-flex p-2 ${variativeClasses}`}
            onClick={() => null}
            role="button"
            tabIndex={-1}
          >
            {fieldContent}
          </div>
          <Input
            type="text"
            name="value"
            autoComplete="off"
            className="d-none py-4 signature-field"
            {...defaultValueParam}
          />
        </div>
      </div>
    );
  };

  const renderAttachmentField = () => (
    <>
      <Input
        className={`d-none attachments_${field?.key}`}
        type="text"
        name="value"
        value=""
        readOnly
        autoComplete="off"
        onClick={() => onClick(field.key || 0)}
      />
      <AttachmentDropzone
        key={`attachments_${field.key}`}
        fieldKey={field.key}
        fieldValue=""
        helpText=""
        properties={field.properties}
        onChangeCount={() => null}
        onRemoveValue={() => null}
        isValid
        errorMessage=""
        isPDFDocument
        field={field}
        useType={FORM_TYPE}
      />
    </>
  );

  const renderQuestionField = () => (
    field.answerType === QUESTION_ANSWER_STRING
      ? renderTextField()
      : createSelectField()
  );

  const renderCheckboxField = () => (
    <div className="d-flex align-items-center w-100 py-2">
      <Checkbox
        checked={false}
        onChange={() => null}
        name="value"
        disabled
        sx={{
          padding: 0,
        }}
      />
      <span className="px-1">
        {field.fieldName}
      </span>
    </div>
  );

  const renderParagraphField = () => (
    <FormBuilderDescription
      description={field.value || ''}
      setDescription={(value: string) => {
        dispatch(updateParagraphValue(value, sectionKey, field.key ?? 0, formBuilderType));
      }}
      // It's necessary to recalculate the whole element height if description editor is opened or closed.
      recalculateHeight={() => {
        setTimeout(() => {
          if (fieldRef.current !== null) {
            const height = getElementSize(fieldRef.current, ['height']);
            cache.set(index, 1, width, height);
            measure();
          }
        }, 0);
      }}
    />
  );

  const renderSelectRadioOrCheckboxField = (selectFieldType: SelectFieldOptionsType) => (
    <div className="form-radio-select editor-field-view-wrapper">
      <RadioSelectElement
        element={field}
        viewMode={selectFieldType}
        onChangeRadioOption={() => null}
        isReadOnlyMode
        className="form"
      />
    </div>
  );

  const getFieldHandler = (
    fieldType: string = '',
    selectFieldType: SelectFieldOptionsType | undefined = undefined,
  ) => {
    switch (fieldType) {
      case TEXT_FIELD:
        return renderTextField();
      case DATE_FIELD:
        return renderDateField();
      case TEXTAREA_FIELD:
        return renderTextArea();
      case SELECT_FIELD:
        if (selectFieldType && getIsRadioOrCheckbox(selectFieldType)) {
          return renderSelectRadioOrCheckboxField(selectFieldType);
        }
        return createSelectField();
      case SIGNING_FIELD:
        return renderSigningField();
      case ATTACHMENT_FIELD:
        return renderAttachmentField();
      case QUESTION_FIELD:
        return renderQuestionField();
      case CHECKBOX_FIELD:
        return renderCheckboxField();
      case FORM_BUILDER_PARAGRAPH:
        return renderParagraphField();
      default:
        return renderTextField();
    }
  };

  const assignmentColor = useAssignmentFieldColor(field.assignment ?? SignerType.RECIPIENT);

  return (
    <div className="form-builder_section_field-view">
      <div className="form-builder_sort_button_wrapper">
        <ElementSortButton className="sort_button" />
      </div>
      <div className="field_wrapper" ref={fieldRef}>
        <FieldName
          name={field.fieldName ?? field.name ?? getFieldName(field, false)}
          isRequired={field.requiredField || false}
          additionalClassName="label-text-field-editable"
          additionalStyles={{
            color: assignmentColor,
          }}
        />
        <FormBuilderFieldActions
          sectionKey={sectionKey}
          shouldDisableActions={field.type === FORM_BUILDER_PARAGRAPH}
          fieldPosition={field.position ?? 0}
          formBuilderType={formBuilderType}
          fieldKey={field.key ?? 0}
          onDeleteParagraphField={onDeleteParagraphField}
        >
          {getFieldHandler(field.type, field.selectFieldType)}
        </FormBuilderFieldActions>
      </div>
    </div>
  );
};
export default FormBuilderFieldView;