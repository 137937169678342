import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Descendant } from 'slate';

import { PDF_TYPE, URL_PUBLIC_SUCCESS_SUMMARY_PAGE } from 'constants/general';
import { ShareViewModes } from 'constants/shareViewModes';
import useEditorContent from 'hooks/useEditorContent';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import useRedirectToPage from 'hooks/useRedirectToPage';
import useSaveManagerPageHandler from 'hooks/useSaveManagerPageHandler';
import useSavePublicPageHandler from 'hooks/useSavePublicPageHandler';
import { deletePersistentStorageDataByKey } from 'store/actions/persistentReduxStorage';
import { setPublicPageCurrentDocument } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { FieldTypeOption, PDFFieldTypeObject } from 'types/PdfTemplates';
import { IPublicPageGeneralStructure, IPublicPageState, PublicPageDataType } from 'types/PublicPage';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { getAmplitudeEventName } from 'utils/amplitude/amplitudeUtils';
import { COLLECTION_WAS_SENT } from 'utils/amplitude/amplituteConstants';
import { isManagerAssignment, isRecipientAssignment } from 'utils/assignmentsHelpers';
import getAssignmentByUrl from 'utils/getAssignmentByUrl';
import { getFieldsFromContentByAssingment } from 'utils/PublicPage/getFieldsFromData';
import { getEditorPageLink } from 'utils/routeHelpers';
import { isCollectionType } from 'utils/typeChecker';

const useSaveDocumentHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data,
    structure,
    documentId,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const { documents, main }: IPublicPageGeneralStructure = structure;
  const { isButtonSubmit } = useSelector((state: RootStateType) => state.validation);
  const key = useSelector((state: RootStateType) => state.publicPages.structure.main.id);
  const pdfTemplateFields: PDFFieldTypeObject = useSelector(
    (state: RootStateType) => state.pdfTemplates.pdfTemplateFields,
  );

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState<boolean>(false);
  const [showNoDataEnteredModal, setShowNoDataEnteredModal] = useState<boolean>(false);
  const [isDisabledModalButton, setDisabledModalButton] = useState<boolean>(false);

  const pageAssignment = getAssignmentByUrl(main.pageType);

  const [content, setContent] = useEditorContent();

  const isReadOnlyMode: boolean = useReadOnlyMode();
  const [savePublicPage, isVisibleAttachmentLoader] = useSavePublicPageHandler();
  const [
    saveManagerPage,
    isVisibleAttachmentLoaderManager,
  ] = useSaveManagerPageHandler(main.mainAssignment ?? pageAssignment);
  const redirectToSummaryPage = useRedirectToPage(`/${URL_PUBLIC_SUCCESS_SUMMARY_PAGE}`);

  const isLoaderVisible = isVisibleAttachmentLoader || isVisibleAttachmentLoaderManager;

  useEffect(() => {
    setDisabledModalButton(isButtonSubmit);
  }, [isButtonSubmit]);

  const documentsCount: number = documents.length;

  const isReadOnlyDocument: boolean = (
    documentsCount === 1 && documents[0].emptyDocument
  ) || isReadOnlyMode;

  const saveDocumentHandler = async (
    isUnfinished: boolean = false,
    documentUUID: string | null = null,
    updatedData: PublicPageDataType | null = null,
  ) => {
    if (typeof isUnfinished !== 'boolean' || (typeof isUnfinished === 'boolean' && !isUnfinished)) {
      setDisabledModalButton(true);
      dispatch(deletePersistentStorageDataByKey(key));
    }

    if (data && data.id) {
      if (isRecipientAssignment(pageAssignment)) {
        savePublicPage(data, main.id, isUnfinished, documentUUID, updatedData);
      }
      if (isManagerAssignment(pageAssignment)) {
        saveManagerPage(data, main.id);
      }
    }

    trackAmplitudeEvent({
      eventName: isCollectionType(data) ? COLLECTION_WAS_SENT : getAmplitudeEventName({ data }).wasSent,
    });
  };

  const recipientFlowFinished = () => {
    setIsShowConfirmationModal(false);
    redirectToSummaryPage();
  };

  const managerFlowFinished = () => {
    dispatch(setPublicPageCurrentDocument(null));
    history.push(getEditorPageLink(String(documentId), data?.type, true));
  };

  const onFinishRedirectHandler = isRecipientAssignment(pageAssignment)
    ? recipientFlowFinished
    : managerFlowFinished;

  const shouldShowNoDataEnteredModalWindow = (content: Descendant[]) => {
    let currentAssingmentFields: any[] = [];
    if (data?.view_mode === ShareViewModes.SHARE_MODE_FORM_BUILDER) {
      currentAssingmentFields = Object.values(structure.formBuilderFields);
    } else {
      currentAssingmentFields = getFieldsFromContentByAssingment({
        content: data?.type === PDF_TYPE ? pdfTemplateFields : content,
        assignment: structure.main.mainAssignment || '',
        isPDF: data?.type === PDF_TYPE,
      });
    }
    if (
      currentAssingmentFields.every(
        (field) => !field.value && !field.options?.some((option: FieldTypeOption) => option.checked) && !field.checked,
      )) {
      setShowNoDataEnteredModal(true);
      return true;
    }
    return false;
  };

  const onConfirmNoDataEnteredModalWindow = () => {
    setShowNoDataEnteredModal(false);
    setIsShowConfirmationModal(true);
  };

  const onCancelNoDataEnteredModalWindow = () => {
    setShowNoDataEnteredModal(false);
  };

  return {
    isReadOnlyDocument,
    isShowConfirmationModal,
    setIsShowConfirmationModal,
    isDisabledModalButton,
    documentsCount,
    saveDocumentHandler,
    isLoaderVisible,
    onFinishRedirectHandler,
    shouldShowNoDataEnteredModalWindow,
    showNoDataEnteredModal,
    onConfirmNoDataEnteredModalWindow,
    onCancelNoDataEnteredModalWindow,
    content,
    setContent,
  };
};

export default useSaveDocumentHandler;