/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';

import isBase64 from 'is-base64';
import { Descendant, Editor as SlateEditor, Element as SlateElement, Node, NodeEntry, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  ANSWER_TYPE_KEY,
  ATTACHMENT_DEFAULT_PARAMS,
  ATTACHMENT_FIELD,
  DATE_FIELD,
  DEFAULT_FIELD_VALUE_LENGTH,
  FIELD_GROUPING_VALUE,
  // FIELD_REQUIRED_KEY,
  FIELD_SUBTYPE_KEY,
  FIELD_TYPE,
  GROUPED_FIELDS,
  LABEL_CONFIGURATION_KEY,
  MAX_LENGTH_KEY,
  MAX_SYMBOL_SIZE,
  NONE_FIELD_VALUE_LENGTH,
  PARAGRAPH,
  QUESTION_ANSWER_STRING,
  QUESTION_FIELD,
  RECIPIENT_ASSIGNMENT,
  SECTION,
  SELECT_FIELD,
  SELECT_FIELD_TYPE_CHECKBOX,
  SELECT_FIELD_TYPE_RADIO,
  SELECT_FIELD_TYPE_SELECT,
  SHORT_NAME_KEY,
  SIGNING_FIELD,
  TEXT_FIELD,
} from 'constants/editors';
import { SIGNATURE_TABS } from 'constants/signatures';
import { changeSelectedField, setSelectFieldError } from 'store/actions/editorSlate';
import { setSelectedFieldKey } from 'store/actions/pdfTemplates';
import { setPersistentStorageFieldData } from 'store/actions/persistentReduxStorage';
import {
  saveTemporaryPIIUserDataInDatabase,
} from 'store/actions/publicPages';
import {
  BlockFormatType,
  FieldAssignmetsType,
  FieldGroupByType,
  FieldType,
  FilterCallbackType,
  ICustomElement,
  isBlockFormatType,
  ISubtypesFromFieldsGetter,
  IUpdatedSelectFieldOptions,
  IUpdateSelectFieldOptionsHandlerProps,
  LABEL_CONFIGURATION,
  SelectFieldOptionType,
} from 'types/Editor';
import {
  IFieldsGroupedByPropertyProps,
  IUpdateGroupedByParamFieldValuesProps,
} from 'types/GroupedFieldUtils';
import { PDFFieldType, PDFFieldTypeObject, PDFFieldTypesType } from 'types/PdfTemplates';
import { ISelectFieldType } from 'types/Properties';
import { ITemplateSection } from 'types/redux';
import { PIIDataType } from 'types/userProfile';
import { createNameConstant } from 'utils/createNameConstant';
import { getSectionsWithFieldIDs } from 'utils/editorSectionHelpers';
import { getIsSelectField } from 'utils/Fields/fieldsTypeChecker';
import { getFormsForFields } from 'utils/Fields/getFormsForFields';
import { isNotEmptyObject } from 'utils/isEmptyObject';
// import { isCheckboxFieldType } from 'utils/pdfTemplatesHelpers';
import getFieldName from 'utils/PublicPage/getFieldName';
import { updateNodeWithoutSubtypes } from 'utils/updateNode';

export const generateNumber = (startKey = '', keyLength = 8): number => {
  let randomPoz;
  const chars = '0123456789';
  let key = startKey;
  for (let i = 0; i <= keyLength; i++) {
    if (String(key).length >= keyLength) {
      // eslint-disable-next-line no-continue
      continue;
    }
    randomPoz = Math.floor(Math.random() * chars.length);
    key += chars.substring(randomPoz, randomPoz + 1);
  }
  if (chars.length < keyLength) {
    return Number(generateNumber(key));
  }
  return Number(key);
};

export const getCurrentDate = () => (
  new Date().toLocaleDateString('en-US')
);

export const shouldApplyTodaysDate = (
  fieldAssignment: string | undefined,
  currentDocAssignment: string,
) => (fieldAssignment === currentDocAssignment);

export const getValidDate = (target: any): string => {
  const currentValue = target.value || '';
  let newValue = currentValue;
  if (target.type === 'date' && currentValue.length > 10) {
    newValue = currentValue.slice(currentValue.length - 10, currentValue.length);
    // eslint-disable-next-line no-param-reassign
    target.value = newValue;
  }
  return newValue;
};

export const addPropertiesByType = (
  field: any,
  format: BlockFormatType | PDFFieldTypesType,
) => {
  const updatedField = field;
  switch (format) {
    case DATE_FIELD:
      updatedField.isTodaysDate = false;
      return updatedField;
    case QUESTION_FIELD:
      updatedField.answerType = QUESTION_ANSWER_STRING;
      updatedField.shortName = '';
      return updatedField;
    case ATTACHMENT_FIELD:
      updatedField.properties = {
        ...ATTACHMENT_DEFAULT_PARAMS,
        ...updatedField.properties,
      };
      return updatedField;
    case SIGNING_FIELD:
      updatedField.signatureTab = SIGNATURE_TABS.DRAW_TAB;
      return updatedField;
    case SELECT_FIELD:
      updatedField.addedOtherOption = false;
      updatedField.selectedOtherOption = false;
      return updatedField;
    default:
      return updatedField;
  }
};

export const createField = (
  format: BlockFormatType,
  isCollapsed: boolean | null,
  formLength: number = 0,
  assignment: string = RECIPIENT_ASSIGNMENT,
): SlateElement => {
  const fieldWithLength = {
    maxLength: NONE_FIELD_VALUE_LENGTH,
  };
  const field: SlateElement = {
    key: generateNumber(),
    type: format,
    assignment,
    name: '',
    value: '',
    position: formLength,
    children: isCollapsed ? [{ text: 'field name' }] : [],
    ...(format === 'text-field' && fieldWithLength),
  };
  return addPropertiesByType(field, format);
};

export const createSectionWithField = (
  sectionKey = 0,
  position = 0,
  sectionName = '',
  children: Descendant[] = [],
): SlateElement => {
  const childrenContent = children.length
    ? children
    : (
      [{
        type: PARAGRAPH,
        key: generateNumber(),
        children: [{
          ...createField('text-field', true),
          children: [
            { text: 'New Field' },
          ],
        }],
      }]
    );

  return {
    type: SECTION,
    name: sectionName,
    key: sectionKey || generateNumber(),
    position,
    children: childrenContent as SlateElement[],
  };
};

export const findAndChangeDuplicatedFieldKeys = (fieldKey: number, editor: SlateEditor) => {
  const selectedCells = Array.from(
    SlateEditor.nodes(editor, {
      match: (n) => SlateElement.isElement(n) && n.key === fieldKey,
    }),
  );
  if (selectedCells.length > 1) {
    selectedCells.forEach((fieldData, index) => {
      const [field, path]: NodeEntry = fieldData;
      const nameData: Descendant | undefined = field.children && field.children[0];
      if (!nameData?.text) {
        Transforms.unwrapNodes(editor, { at: path });
      } else if (index > 0) {
        Transforms.setNodes(editor, { key: generateNumber() }, { at: path });
      }
    });
  }
};

export const isNodeTextNotEmpty = (nodeData: Node): boolean => nodeData.text?.trim() !== '';

export const filterFormFieldsFromContent = (
  content: Descendant[] | PDFFieldTypeObject,
  assignmentType: FieldAssignmetsType | string = RECIPIENT_ASSIGNMENT,
): SlateElement[] => {
  const currentForm: SlateElement[] = [];

  const filterFormFields = (data: Descendant[] | PDFFieldTypeObject, currentForm: SlateElement[]) => {
    const resultElements = JSON.parse(JSON.stringify(data)).filter((node: any) => {
      // eslint-disable-next-line no-param-reassign
      if (node.children) node.children = filterFormFields(node.children, currentForm);
      return FIELD_TYPE.includes(node?.type) && assignmentType === node.assignment;
    });
    if (resultElements.length) {
      resultElements.forEach((element: SlateElement) => {
        currentForm.push(element);
      });
    }
    return resultElements;
  };
  filterFormFields(content, currentForm);

  return currentForm;
};

export const createFieldName = (array: any[]) => (
  array.reduce((acc: any, current: any) => `${acc}${current.text}`, '')
);

export const createCustomSubtypeName = (fieldType: string, fieldName: string) => (
  `${fieldType.replaceAll('-', '_')}__${fieldName.replaceAll(' ', '_').toLowerCase()}`
);

export const createKeyArray = (array: any): number[] => array.map((element: any) => element.key);

export const isFindField = (node: Descendant, key?: number | null) => (
  SlateElement.isElement(node) && FIELD_TYPE.includes(node.type) && node.key === key
);

export const isSlateEditorField = (node: Descendant): boolean => (
  SlateElement.isElement(node) && FIELD_TYPE.includes(node.type)
);

export const isFieldGroupedByAssignment = (groupBy: string): boolean => groupBy === FIELD_GROUPING_VALUE.BY_ASSIGNEE;
export const isFieldGroupedBySection = (groupBy: string): boolean => groupBy === FIELD_GROUPING_VALUE.BY_SECTION;
export const isFieldGroupedByDocument = (groupBy: string): boolean => groupBy === FIELD_GROUPING_VALUE.BY_DOCUMENT;
export const isFieldGrouped = (groupByValue: string = ''): boolean => {
  const groupingValue: string[] = Object.values(FIELD_GROUPING_VALUE);
  return groupingValue.includes(groupByValue);
};

export const isFieldsGrouped = (
  fieldOne: Partial<ICustomElement | PDFFieldType>,
  fieldTwo: Partial<ICustomElement | PDFFieldType>,
): boolean => {
  if (!isFieldGrouped(fieldOne.groupBy)) return false;
  // TODO Experimental!!!!!
  // if (
  //   fieldTwo.groupBy === FIELD_GROUPING_VALUE.BY_FILTER
  //   && (fieldTwo.filterName === '' || !fieldTwo.filterName)
  // ) return false;

  const areFilterNamesEqual = (
    fieldOne.filterName === fieldTwo.filterName
    || (fieldOne.filterName === undefined && fieldTwo.filterName === '')
    || (fieldOne.filterName === '' && fieldTwo.filterName === undefined)
  );
  const arePIIsEqual = (
    fieldOne.subtype === fieldTwo.subtype
    || (fieldOne.subtype === undefined && fieldTwo.subtype === '')
    || (fieldOne.subtype === '' && fieldTwo.subtype === undefined)
  );

  return fieldOne.groupBy === fieldTwo.groupBy
    && fieldOne.groupByKey === fieldTwo.groupByKey
    && fieldOne.name === fieldTwo.name
    && fieldOne.assignment === fieldTwo.assignment
    && fieldOne.type === fieldTwo.type
    && areFilterNamesEqual
    && arePIIsEqual;
};

export const getSubtypesFromFields = (
  fields: any,
  assignmentType: string = RECIPIENT_ASSIGNMENT,
  filterSubtype: string = '',
): ISubtypesFromFieldsGetter[] => {
  const fieldsWithSubtypes = fields
    .filter((element: any) => element?.subtype && element.assignment === assignmentType)
    .map((element: any) => ({
      key: element.key,
      subtype: element.subtype,
      value: element.value,
      maxLength: element.maxLength,
      requiredField: element.requiredField,
      validationText: element.validationText,
    }));

  if (filterSubtype.length > 0) {
    return fieldsWithSubtypes.filter((element: any) => element.subtype === filterSubtype);
  }

  return fieldsWithSubtypes;
};

export const getPIIFieldWithValueFromDocument = (
  fields: Partial<ICustomElement | PDFFieldType>[],
  assignmentType: string = RECIPIENT_ASSIGNMENT,
): PIIDataType[] => (
  fields
    .filter((field) => field.subtype && field.assignment === assignmentType)
    .map((field): PIIDataType => ({
      subtype: field.subtype,
      value: field.value ?? '',
      filterName: field.filterName,
      groupBy: field.groupBy,
      groupByKey: field.groupByKey,
    }))
);

export const getSimilarSubtypeElementsByKey = (
  fieldKey: number,
  fieldsWithSubtypes: any[],
  formElements: Partial<SlateElement>[],
) => {
  const subtypeIdsWithoutCurrent = createKeyArray(
    fieldsWithSubtypes.filter((field: any) => field.key !== fieldKey),
  );
  return formElements.filter((field: Partial<SlateElement>) => subtypeIdsWithoutCurrent.includes(Number(field.key)));
};

const showProperty = (isHidden: boolean): boolean => !(isHidden);

const showSybtypeProperty = (fieldType: string, propertyKey: string): boolean => (
  (fieldType !== TEXT_FIELD && propertyKey !== FIELD_SUBTYPE_KEY) || fieldType === TEXT_FIELD
);

const showAnswerType = (fieldType: string, propertyKey: string): boolean => (
  !(fieldType !== QUESTION_FIELD && [SHORT_NAME_KEY, ANSWER_TYPE_KEY].includes(propertyKey))
);

// const showRequiredProperty = (fieldType: string, propertyKey: string) => (
//   !(isCheckboxFieldType(fieldType) && propertyKey === FIELD_REQUIRED_KEY)
// );

const showLabelConfigurationProperty = (fieldType: string, propertyKey: string) => (
  !([ATTACHMENT_FIELD, QUESTION_FIELD].includes(fieldType) && propertyKey === LABEL_CONFIGURATION_KEY)
);

const showMaxLengthProperty = (fieldType: string, propertyKey: string) => (
  !(![TEXT_FIELD].includes(fieldType) && propertyKey === MAX_LENGTH_KEY)
);

export const isShownProperty = (fieldType: string, isHidden: boolean, propertyKey: string) => (
  showProperty(isHidden)
  && showSybtypeProperty(fieldType, propertyKey)
  && showAnswerType(fieldType, propertyKey)
  // && showRequiredProperty(fieldType, propertyKey)
  && showLabelConfigurationProperty(fieldType, propertyKey)
  && showMaxLengthProperty(fieldType, propertyKey)
);

export const updateSubtypeContentNodes = (
  content: Descendant[],
  updatedFields: Partial<ICustomElement>[],
): Descendant[] => {
  const callbackFindAndUpdate = (
    nodes: Descendant[],
    callback: FilterCallbackType<Descendant>,
  ): Descendant[] => nodes.map((node) => {
    const newNode = callback(node);
    if (!newNode.children) return newNode;
    return {
      ...newNode,
      children: callbackFindAndUpdate(newNode.children, callback),
    };
  });

  return callbackFindAndUpdate(content, (node: any) => {
    const updatedFieldData = updatedFields.find((field) => (
      node.key === field.key
    ));
    if (updatedFieldData) {
      return {
        ...node,
        value: updatedFieldData.value,
        ...(updatedFieldData.groupByKey && { groupByKey: updatedFieldData.groupByKey }),
      };
    }
    return node;
  });
};

export const setSelectedFieldState = (
  selectedFieldKey: number | null,
  fieldKey: number | undefined,
  dispatch: Dispatch,
  isPdfFormField = false,
): boolean => {
  if (selectedFieldKey === fieldKey || !fieldKey) {
    return false;
  }

  if (isPdfFormField) {
    dispatch(setSelectedFieldKey(fieldKey));
  } else {
    dispatch(changeSelectedField(fieldKey));
  }
  return true;
};

export const removeSlateSelectedFieldError = (
  editor: SlateEditor,
  format: BlockFormatType,
  isActive: boolean,
  dispatch: Dispatch,
) => {
  const [selectedField]: any = SlateEditor.nodes(editor, {
    match: (node) => (
      !SlateEditor.isEditor(node)
      && SlateElement.isElement(node)
      && isBlockFormatType(node.type)
      && node.type
      && (isActive ? node.type === format : node.type !== format)
    ),
  });
  if (selectedField) {
    const [fieldData] = selectedField;
    dispatch(setSelectFieldError(false, fieldData.key));
  }
};

export const isFieldSimilarByName = (filterName: string, fieldName: string, notExact: boolean) => {
  const uppercasedFilterName = createNameConstant(filterName.trim());
  const uppercasedFieldName = createNameConstant(fieldName.trim());
  if (notExact) {
    return uppercasedFieldName.includes(uppercasedFilterName);
  }
  return uppercasedFieldName === uppercasedFilterName;
};

export const isFieldGroupedBySwitcher = (groupBy: string, filterGroupBy: string) => {
  switch (filterGroupBy) {
    case FIELD_GROUPING_VALUE.BY_ASSIGNEE:
      return isFieldGroupedByAssignment(groupBy);
    case FIELD_GROUPING_VALUE.BY_SECTION:
      return isFieldGroupedBySection(groupBy);
    case FIELD_GROUPING_VALUE.BY_DOCUMENT:
      return isFieldGroupedByDocument(groupBy);
    default:
      return false;
  }
};

const isFieldKeyInCurrentSection = (
  elementKey: number,
  filterGroupBy: string,
  custom: {
    editor: SlateEditor,
    selectedField: number,
  } | undefined = undefined,
) => {
  let filterOnlyFieldKeys: number[] = [];
  if (custom) {
    if (isFieldGroupedBySection(filterGroupBy)) {
      const sectionsData = custom.editor ? getSectionsWithFieldIDs(custom.editor) : [];
      const currentSection = sectionsData.find(
        (section) => section.fieldKeys.length && (section.fieldKeys).includes(custom.selectedField),
      );
      filterOnlyFieldKeys = currentSection?.fieldKeys || [];
    }
    if (isFieldGroupedByDocument(filterGroupBy)) {
      const sectionsData = custom.editor ? getSectionsWithFieldIDs(custom.editor) : [];
      sectionsData.forEach((section) => {
        filterOnlyFieldKeys = filterOnlyFieldKeys.concat(section.fieldKeys);
      });
    }
  }
  if (filterOnlyFieldKeys.length) {
    return filterOnlyFieldKeys.includes(elementKey);
  }
  return true;
};

export const getFieldsGroupedByProperty = ({
  fields,
  filterName,
  filterType,
  assignmentType = RECIPIENT_ASSIGNMENT,
  findSimilar = false,
  filterGroupBy = FIELD_GROUPING_VALUE.BY_ASSIGNEE,
  custom = undefined,
}: IFieldsGroupedByPropertyProps): Partial<SlateElement>[] => {
  if (!fields || filterName.trim().length === 0) return [];

  return fields.filter((element) => {
    if (
      element.key
      && GROUPED_FIELDS.includes(element.type || '')
      && element.groupBy
      && isFieldGroupedBySwitcher(element.groupBy, filterGroupBy)
      && isFieldKeyInCurrentSection(element.key, filterGroupBy, custom)
      && isFieldSimilarByName(filterName, element.name || '', findSimilar)
      && element.type === filterType
    ) {
      return element.assignment === assignmentType;
    }
    return false;
  });
};

export const searchAllSimilarGroupedFieldsByName = (
  formElements: Partial<SlateElement>[] | null,
  filterName: string,
  editor?: SlateEditor,
  sections?: ITemplateSection[],
  getAllFields: boolean = false,
): { [key: number]: string }[] => {
  if (!formElements || filterName.trim().length === 0) return [];

  const filteredFields = formElements.filter((element) => (
    element.key
    && (getAllFields || (element.type === TEXT_FIELD && element.groupBy))
    && isFieldSimilarByName(filterName, element.name || '', true)
  ));

  const sectionsData = editor ? getSectionsWithFieldIDs(editor) : [];

  return filteredFields.map((field) => {
    const fieldKey = field.key || 0;
    const fieldName = field.children ? createFieldName(field.children) : (field.fieldName || '');
    if (field.groupBy && fieldKey) {
      if (isFieldGroupedByAssignment(field.groupBy)) {
        return { [fieldKey]: `Assignee - ${fieldName}` };
      }
      if (isFieldGroupedBySection(field.groupBy) || isFieldGroupedByDocument(field.groupBy)) {
        const currentSection = sectionsData.find(
          (section) => section.fieldKeys.length && (section.fieldKeys).includes(fieldKey),
        );
        let sectionName = currentSection?.name || 'Section';
        if (sections && currentSection) {
          const section = sections.find((section) => section.key === currentSection.key);
          if (section) {
            sectionName = section.name || '';
          }
        }
        return { [fieldKey]: `${sectionName} - ${fieldName}` };
      }
    }
    const itemText = getAllFields && !field.groupBy ? 'Field' : 'Grouped field';
    return { [fieldKey]: `${itemText} - ${fieldName}` };
  });
};

export const searchAllGroupedFields = (
  formElements: SlateElement[] | null,
  element: {
    assignment: FieldAssignmetsType | undefined,
    name: string,
    groupBy: string,
    type: BlockFormatType
  },
  editor?: SlateEditor,
  sections?: ITemplateSection[],
) => {
  if (isFieldGroupedByAssignment(element.groupBy)) {
    const fieldList = getFieldsGroupedByProperty({
      fields: formElements,
      filterName: element.name,
      filterType: element.type,
      assignmentType: element.assignment,
      findSimilar: true,
    });
    return fieldList.map((field) => ({
      [field.key || 0]: `Assignee - ${field.children ? createFieldName(field.children) : ''}`,
    }));
  }

  if (isFieldGroupedBySection(element.groupBy)) {
    const fieldList = getFieldsGroupedByProperty({
      fields: formElements,
      filterName: element.name,
      filterType: element.type,
      assignmentType: element.assignment,
      findSimilar: true,
      filterGroupBy: element.groupBy,
    });
    return fieldList.map((field) => ({
      [field.key || 0]: `Grouped by section - ${field.children ? createFieldName(field.children) : ''}`,
    }));
  }

  if (element.name) {
    return searchAllSimilarGroupedFieldsByName(formElements, element.name, editor, sections);
  }

  return [];
};

export const getGroupedFields = (
  allFields: Partial<ICustomElement | PDFFieldType>[] | null,
  selectedKey: number | null,
): Partial<ICustomElement | PDFFieldType>[] => {
  if (!allFields || !selectedKey) return [];

  const selectedFieldData = allFields.find(
    (element) => element && selectedKey === element.key,
  ) ?? null;

  if (!selectedFieldData) return [];

  if (isFieldGrouped(selectedFieldData.groupBy)) {
    return allFields.filter((field) => isFieldsGrouped(field, selectedFieldData));
  }

  return [selectedFieldData];
};

export const updateGroupedByParamFieldValues = ({
  formElements,
  element,
  selectFieldProps,
  editor,
  dispatch,
  newValue = '',
  storageDocumentKey,
  updateNodeHandler = false,
  addedOtherOption = false,
}: IUpdateGroupedByParamFieldValuesProps): null => {
  const usedGroupedFields = getGroupedFields(formElements, element.key ?? 0);

  usedGroupedFields
    .filter((field) => element.key !== field.key)
    .forEach((field) => {
      if (updateNodeHandler) {
        updateNodeWithoutSubtypes(
          editor,
          selectFieldProps && isNotEmptyObject(selectFieldProps)
            ? selectFieldProps
            : { value: newValue },
          field.key,
        );
      } else {
        const fieldPath = ReactEditor.findPath(editor, field as Descendant);
        const isSelectFieldValue = selectFieldProps && isNotEmptyObject(selectFieldProps);
        Transforms.setNodes(
          editor,
          isSelectFieldValue
            ? { ...selectFieldProps, addedOtherOption }
            : { value: newValue, addedOtherOption },
          { at: fieldPath },
        );
      }
    });

  if (dispatch && element.subtype) {
    dispatch(saveTemporaryPIIUserDataInDatabase([{
      subtype: element.subtype,
      value: newValue,
      groupBy: element.groupBy,
      groupByKey: element.groupByKey,
      filterName: element.filterName,
    }]));
  }

  if (dispatch && storageDocumentKey) {
    usedGroupedFields.forEach((field) => {
      const isSelectFieldValue = selectFieldProps && isNotEmptyObject(selectFieldProps);
      if (field.key) {
        dispatch(setPersistentStorageFieldData(
          storageDocumentKey,
          field.key,
          isSelectFieldValue
            ? { ...selectFieldProps, addedOtherOption }
            : { value: newValue, addedOtherOption },
        ));
      }
    });
  }

  return null;
};

export const getGroupedFieldKeys = (
  allFields: (ICustomElement | PDFFieldType)[],
  selectedKey: number | null,
): number[] => getGroupedFields(allFields, selectedKey).map((field) => field.key ?? 0);

export const getGroupedByParamFieldIDs = (
  allFields: (ICustomElement | PDFFieldType)[] | null,
  currentField: {
    key: number,
    assignment: FieldAssignmetsType | undefined,
    name: string,
    groupBy: FieldGroupByType | undefined,
    type: BlockFormatType,
  },
  editor: SlateEditor | undefined = undefined,
): number[] => {
  const custom = editor && {
    editor,
    selectedField: currentField.key,
  };
  const usedGroupedFields = getFieldsGroupedByProperty({
    fields: allFields,
    filterName: currentField.name,
    filterType: currentField.type,
    assignmentType: currentField.assignment,
    findSimilar: false,
    filterGroupBy: currentField.groupBy,
    custom,
  });
  return usedGroupedFields.map((field) => Number(field.key)) || [];
};

export const getFieldIDsToSelect = (
  currentField: SlateElement | PDFFieldType,
  selectedKey: number | null,
  allFields: (SlateElement | PDFFieldType)[],
  editor: SlateEditor | undefined = undefined,
  includeSubtypes = false,
): number[] => {
  let fieldsIDsToSelect: number[] = [];

  const selectedFieldData = selectedKey && allFields && (allFields as SlateElement[]).find(
    (element) => element && selectedKey === element.key,
  );
  if (!selectedFieldData) return fieldsIDsToSelect;

  const { key, name, assignment, groupBy, subtype, type } = selectedFieldData;

  if (isFieldGrouped(groupBy)) {
    fieldsIDsToSelect = getGroupedByParamFieldIDs(
      allFields,
      { key: key || 0, name: name || '', assignment, groupBy, type },
      editor,
    );
  } else if (includeSubtypes && subtype) {
    fieldsIDsToSelect = getSubtypesFromFields(
      allFields,
      assignment,
      subtype,
    ).map((field) => field.key);
  }

  if (currentField.key && key === currentField.key && !fieldsIDsToSelect.includes(currentField.key)) {
    fieldsIDsToSelect.push(currentField.key);
  }

  return fieldsIDsToSelect;
};

export const isFieldHasSubtype = (subtype: string = '') => subtype.length > 0;

export const getSelectedFieldClass = (
  idsToSelect: number[],
  currentKey: number | undefined,
  hasSubtype: boolean = false,
) => {
  const selectedFieldClass = idsToSelect.includes(Number(currentKey)) ? ' selected-field' : '';
  const subtypeFieldClass = hasSubtype && selectedFieldClass.length ? ' light-red-highlight' : '';
  return `${selectedFieldClass}${subtypeFieldClass}`;
};

export const getPublicSelectedFieldClass = (
  field: Partial<SlateElement | PDFFieldType>,
  selectedKey: number | undefined,
  idsToSelect: number[],
) => {
  const hasSubtype = isFieldHasSubtype(field.subtype);
  const showSelectedStyles = selectedKey && (isFieldGrouped(field.groupBy) || hasSubtype);
  const selectedFieldClasses = showSelectedStyles
    ? getSelectedFieldClass(idsToSelect, selectedKey, hasSubtype)
    : '';
  const fieldClasses = ' public-form-field';
  return `${fieldClasses}${selectedFieldClasses}`;
};

export const getSelectedFieldClassName = (
  field: SlateElement,
  selectedField: number | null,
  editor: SlateEditor,
): string => {
  let idsToSelect: number[] = [Number(selectedField)];
  const hasSubtype = isFieldHasSubtype(field.subtype);

  if (isFieldGrouped(field.groupBy) || hasSubtype) {
    const forms = getFormsForFields(editor) || [];
    idsToSelect = getFieldIDsToSelect(field, selectedField, forms, editor, hasSubtype);
  }

  return getSelectedFieldClass(idsToSelect, field.key, hasSubtype);
};

export const getCurrentFieldPropertiesToUpdate = ({
  assignmentValue,
  filterGroupByValue,
  fieldList,
  field,
  custom,
}: {
  assignmentValue: string,
  filterGroupByValue: string,
  fieldList: Partial<SlateElement>[] | Partial<PDFFieldType>[],
  field: {
    name: string,
    key: number,
    type: BlockFormatType,
  },
  custom?: { editor: SlateEditor, selectedField: number },
}) => {
  let fieldDataToUpdate = {};

  if (isFieldGrouped(filterGroupByValue)) {
    const groupedFieldsExceptCurrent = getFieldsGroupedByProperty({
      fields: fieldList,
      filterName: field.name,
      filterType: field.type,
      assignmentType: assignmentValue,
      findSimilar: false,
      filterGroupBy: filterGroupByValue,
      custom,
    }).find((element) => element.key !== field.key);
    if (groupedFieldsExceptCurrent) {
      const selectPropertiesToUpdate = getIsSelectField(field.type)
        ? {
          selectFieldType: groupedFieldsExceptCurrent.selectFieldType,
          selectedOtherOption: groupedFieldsExceptCurrent.selectedOtherOption,
          options: groupedFieldsExceptCurrent.options, // with the same IDs
          addedOtherOption: groupedFieldsExceptCurrent.addedOtherOption,
        }
        : {};
      const todayDate = groupedFieldsExceptCurrent.isTodaysDate !== undefined
        && { isTodaysDate: groupedFieldsExceptCurrent.isTodaysDate };
      fieldDataToUpdate = {
        subtype: '',
        value: groupedFieldsExceptCurrent.value || '',
        helpText: groupedFieldsExceptCurrent.helpText || '',
        requiredField: groupedFieldsExceptCurrent.requiredField || false,
        maxLength: groupedFieldsExceptCurrent.maxLength || '',
        validationText: groupedFieldsExceptCurrent.validationText || '',
        ...todayDate,
        ...selectPropertiesToUpdate,
      };
    }
  }
  return fieldDataToUpdate;
};

export const labelConfigurationHandler = (labelConfiguration: LABEL_CONFIGURATION) => {
  const isLeftLabel = labelConfiguration === LABEL_CONFIGURATION.LEFT;
  const isRightLabel = labelConfiguration === LABEL_CONFIGURATION.RIGHT;
  const isLegalLabel = labelConfiguration === LABEL_CONFIGURATION.LEGAL;
  const isNoneLabel = labelConfiguration === LABEL_CONFIGURATION.NONE;
  const isTopLabel = labelConfiguration === LABEL_CONFIGURATION.TOP;

  return {
    isRightLabel,
    isLegalLabel,
    isLeftLabel,
    isNoneLabel,
    isTopLabel,
  };
};

export const getInputSpanWidthStyle = (maxLength: number | string | undefined): number => {
  if (!maxLength || typeof maxLength === 'string') return DEFAULT_FIELD_VALUE_LENGTH * MAX_SYMBOL_SIZE;
  return maxLength * MAX_SYMBOL_SIZE;
};

export const updateSelectFieldOptionsHandler = <T extends SelectFieldOptionType>({
  options,
  viewMode,
  id,
  selectedIndex,
}: IUpdateSelectFieldOptionsHandlerProps<T>): IUpdatedSelectFieldOptions<T> => {
  let value = '';
  const updatedOptions = options.map((option, index) => {
    let checked: boolean;
    switch (viewMode) {
      case SELECT_FIELD_TYPE_RADIO:
        checked = option.id === Number(id) && !option.checked;
        break;
      case SELECT_FIELD_TYPE_CHECKBOX:
        checked = (option.checked && option.id !== Number(id))
          || (option.id === Number(id) && !option.checked);
        break;
      case SELECT_FIELD_TYPE_SELECT:
      default:
        checked = index === selectedIndex - 1;
        break;
    }
    if (checked) value = option.label;
    return {
      ...option,
      checked,
    };
  });

  return {
    updatedOptions,
    value,
  };
};

export const isBase64String = (value: string | undefined): boolean => {
  if (value) {
    return isBase64(value, { allowMime: true, mimeRequired: true, allowEmpty: false });
  }
  return false;
};

export const getGroupingFilterNameOptions = (fields: FieldType[]): ISelectFieldType[] => {
  const textFields: FieldType[] = fields.filter(
    (field: FieldType): boolean => field.type === TEXT_FIELD,
  );

  const uniqFields: FieldType[] = [];
  const excludedFieldsKeys: Set<number> = new Set();

  textFields.forEach((field: FieldType): void => {
    const key: number = field.key ?? 0;
    if (!excludedFieldsKeys.has(key)) {
      const groupedFieldIds: number[] = getGroupedFieldKeys(textFields, key);
      uniqFields.push(field);
      groupedFieldIds.forEach((id: number) => excludedFieldsKeys.add(id));
    }
  });

  const options = uniqFields.map((field: FieldType, index: number): ISelectFieldType => ({
    id: field.key ?? index + 1,
    type: field.name ?? '',
    label: getFieldName(field, false),
  }));

  options.unshift({
    id: 0,
    type: '',
    label: '',
  });

  return options;
};