import { FC, Fragment } from 'react';

import Draggable, { DraggableData } from 'react-draggable';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';

import { NumberSize, Resizable } from 're-resizable';

import FieldIconWrapper from 'components/PdfEditor/PdfFieldsSidebar/FieldIconWrapper';
import Tooltip from 'components/Tooltip';
import {
  DEFAULT_PDF_FIELD_HEIGHT,
  DEFAULT_PDF_FIELD_WIDTH,
  OPTION,
  PDF_COORDS_Y_COEFFICIENT,
} from 'constants/editors';
import { useAssignmentFieldColor } from 'hooks/signNow';
import { updateFieldByKey } from 'store/actions/pdfTemplates';
import { SelectFieldOptionsType } from 'types/Editor';
import { PDFFieldType, PDFPropsType } from 'types/PdfTemplates';
import { SignerType } from 'types/SendDocuments';
import { createNameConstant } from 'utils/createNameConstant';
import { getCheckboxPrintClass, getInputTypeByViewMode } from 'utils/Fields/checkboxRadioHelpers';
import { getEnableResize } from 'utils/pdfTemplatesHelpers';

import vars from 'variables.module.scss';

type UpdateOptionCoordsPropsType = {
  data: DraggableData;
  field: PDFFieldType;
  optionId: number;
  isSelectOtherOption?: boolean;
}

type UpdateOptionSizePropsType = {
  delta: NumberSize;
  field: PDFFieldType;
  optionID: number;
}

const SelectRadioEditable: FC<PDFPropsType> = ({
  field,
  isReadOnlyMode,
  isPublicPage,
  onChangeRadioSelectField,
  onChangeHandlerOtherOption,
  fieldStyle,
}) => {
  const dispatch = useDispatch();
  const areLabelsHidden: boolean = field.areLabelsHidden ?? true;

  const updateOptionCoords = ({
    data,
    field,
    optionId,
    isSelectOtherOption = false,
  }: UpdateOptionCoordsPropsType) => {
    const newCoords = {
      x: data.x,
      y: data.y,
    };
    const updatedField = {
      ...field,
      options: field.options?.map((option) => {
        if (option.id === optionId) {
          if (isSelectOtherOption) {
            return {
              ...option,
              coordsForOther: newCoords,
            };
          }
          if (!isSelectOtherOption && option.isSelectOtherOption) {
            return {
              ...option,
              coords: newCoords,
              coordsForOther: option.coordsForOther ?? { ...newCoords, y: newCoords.y + PDF_COORDS_Y_COEFFICIENT },
            };
          }
          return {
            ...option,
            coords: newCoords,
          };
        }
        return option;
      }),
    };

    dispatch(updateFieldByKey(field.key, updatedField));
  };

  const updateOptionSize = ({
    delta,
    field,
    optionID,
  }: UpdateOptionSizePropsType) => {
    const updatedField = {
      ...field,
      options: field.options?.map((option) => {
        if (option.id === optionID) {
          return {
            ...option,
            size: {
              width: (option.size?.width || DEFAULT_PDF_FIELD_WIDTH) + delta.width,
              height: (option.size?.height || DEFAULT_PDF_FIELD_HEIGHT) + delta.height,
            },
          };
        }
        return option;
      }),
    };

    dispatch(updateFieldByKey(field.key, updatedField));
  };

  const isResizable: boolean = !isPublicPage && !isReadOnlyMode;
  const enabledResize = getEnableResize(isResizable);

  const HTML_DRAG_AND_DROP_RADIO_CONTAINER = `.drag-and-drop-radio-container-${field.key}`;

  const resizeBoundParent = document
    .querySelector(HTML_DRAG_AND_DROP_RADIO_CONTAINER) as HTMLElement;

  const assignmentColor = useAssignmentFieldColor(
    field.assignment ?? SignerType.RECIPIENT,
    isReadOnlyMode,
  );

  return (
    <div
      className={`pdf-radio-select drag-and-drop-radio-container-${field.key}`}
      data-opaque-background={field.isSelectFieldOpaqueBackground ?? false}
      style={{
        ...fieldStyle,
        backgroundColor: field.isSelectFieldOpaqueBackground ? vars.white : 'transparent',
      }}
    >
      {
        field?.options?.map(({
          id,
          label,
          checked,
          coords,
          isSelectOtherOption,
          coordsForOther,
          size,
        }) => (
          <Fragment key={id}>
            <Draggable
              axis="both"
              disabled={isPublicPage || isReadOnlyMode}
              handle=".pdf-radio-select__label"
              bounds={HTML_DRAG_AND_DROP_RADIO_CONTAINER}
              defaultPosition={{
                x: coords?.x || 0,
                y: coords?.y || 0,
              }}
              onStop={(e, data) => updateOptionCoords({
                data,
                field,
                optionId: id,
              })}
            >
              <Tooltip title={areLabelsHidden ? label : ''} placement="left">
                <div className="pdf-radio-select__label">
                  <Input
                    type={getInputTypeByViewMode(field.selectFieldType as SelectFieldOptionsType)}
                    name={`${createNameConstant(field.fieldName)}-${field.key}`}
                    value={label}
                    checked={checked ?? false}
                    data-value={label}
                    data-id={id}
                    onChange={(event) => onChangeRadioSelectField(event, field)}
                    onClick={(event) => onChangeRadioSelectField(event, field)}
                    disabled={isReadOnlyMode}
                    className={`radio-select-${field.assignment} pdf-radio-select__input ${
                      getCheckboxPrintClass(field.selectFieldType)
                    }`}
                    style={{ borderColor: assignmentColor }}
                    data-pdf-field
                  />
                  {/* added only for pdf files, don't touch */}
                  <span
                    data-some-odd-data-attr
                    className="some-odd-class pdf-radio-select__text"
                  >
                    sel
                  </span>
                  <span
                    className={
                      `pdf-radio-select__text ${areLabelsHidden ? 'transparent-color-text' : 'black-color-text'}`
                    }
                    data-hidden-label={areLabelsHidden}
                  >
                    {label}
                  </span>
                </div>
              </Tooltip>
            </Draggable>
            {
              field.selectedOtherOption && isSelectOtherOption && (
                <Draggable
                  axis="both"
                  disabled={isPublicPage || isReadOnlyMode}
                  handle={`.pdf-radio-select__text-${field.key}`}
                  bounds={HTML_DRAG_AND_DROP_RADIO_CONTAINER}
                  defaultPosition={{
                    x: coordsForOther?.x || 0,
                    y: coordsForOther?.y || 0,
                  }}
                  onStop={(e, data) => updateOptionCoords({
                    data,
                    field,
                    optionId: id,
                    isSelectOtherOption,
                  })}
                >
                  <div className="pdf-radio-select__label">
                    {
                      isResizable && (
                        <div
                          className={`drag-and-drop-cursor--option pdf-radio-select__text-${field.key}
                          drag-and-drop-cursor-${field.assignment}`}
                        >
                          <FieldIconWrapper type={OPTION} />
                        </div>
                      )
                    }
                    <Resizable
                      enable={enabledResize}
                      className="resizable-wrapper"
                      size={{
                        width: (size?.width || DEFAULT_PDF_FIELD_WIDTH),
                        height: (size?.height || DEFAULT_PDF_FIELD_HEIGHT),
                      }}
                      minHeight={DEFAULT_PDF_FIELD_HEIGHT}
                      minWidth={DEFAULT_PDF_FIELD_WIDTH}
                      bounds={resizeBoundParent}
                      onResizeStop={(e, d, r, delta) => updateOptionSize({
                        delta,
                        field,
                        optionID: id,
                      })}
                    >
                      <Input
                        type="text"
                        style={fieldStyle} // important for print view
                        value={field.value || ''} // important for print view
                        data-id={`InputFieldOtherOption-${id}`}
                        onChange={(event) => onChangeHandlerOtherOption(event, field)}
                        disabled={isReadOnlyMode}
                        className={`radio-select-${field.assignment} py-0 mt-1`}
                        data-pdf-field
                      />
                    </Resizable>
                  </div>
                </Draggable>
              )
            }
          </Fragment>
        ))
      }
    </div>
  );
};

export default SelectRadioEditable;