import { useDispatch } from 'react-redux';

import { FormControl, MenuItem, Select } from '@mui/material';

import { setOpenAssignmentsSettingsModal } from 'store/actions/editorSlate';
import { AssignmentType } from 'types/Editor';
import { getHexColorFromIndex } from 'utils/colorAssignmentHelper';

const useEditorAssignmentsMenu = (
  currentAssignment: string,
  options: AssignmentType[],
  updateHandler: (event: any) => void,
  showEditButton: boolean = true,
) => {
  const dispatch = useDispatch();

  const getAssignmentsMenuComponent = () => (
    <div className="editor-assignments">
      <div>
        <label className="mb-0 form-label">Add Assignments</label>
      </div>
      <div className="d-flex">
        <FormControl fullWidth className="set-signer-select">
          <Select
            value={currentAssignment}
            onChange={updateHandler}
          >
            {
            options.map((assignment: AssignmentType, index: number) => (
              <MenuItem
                value={assignment.type}
                key={assignment.type}
              >
                <div
                  className="set-signer-menu-item"
                >
                  <span
                    className="set-signer-icon"
                    style={{
                      background: getHexColorFromIndex(index),
                    }}
                  />
                  <span>
                    {assignment.label}
                  </span>
                  <span>
                    (Signer {index + 1})
                  </span>
                </div>
              </MenuItem>
            ))
          }
          </Select>
        </FormControl>
        {
        showEditButton && (
          <button
            type="button"
            className="ms-2 property-tab-field-button"
            onClick={() => dispatch(setOpenAssignmentsSettingsModal(true))}
          >
            Edit
          </button>
        )
      }
      </div>
    </div>
  );

  return {
    getAssignmentsMenuComponent,
  };
};

export default useEditorAssignmentsMenu;