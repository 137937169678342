import { toast } from 'react-toastify';
import { put, select, takeLatest } from 'redux-saga/effects';

import { STATUS_COMPLETED } from 'constants/documentStatuses';
import {
  getCollectionExecutedById,
  saveCollectionExecutedPublicLink,
  updateCollectionExecutedById,
} from 'services/api/collectionsExecuted';
import {
  CREATE_COLLECTION_EXECUTED_PUBLIC_LINK,
  GET_COLLECTION_EXECUTED_DETAILS,
  UPDATE_COLLECTION_EXECUTED,
} from 'store/actions/actionTypes';
import {
  setAllCollectionsExecuted,
  setCollectionExecutedDetails,
  setCollectionExecutedPublicLink,
} from 'store/actions/collectionsExecuted';
import { setDocumentsLimitLeft } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { ICollectionExecutedDetails, ICollectionExecutedDocument } from 'types/CollectionExecuted';

const getCollectionExecutedDetails = function* ({ payload }: { payload: string }) {
  const collectionExecutedResponse: IResponseOfRequest<
    ICollectionExecutedDetails
  > = yield getCollectionExecutedById(payload);
  yield put(setCollectionExecutedDetails(collectionExecutedResponse.data));
};

const updateCollectionExecuted = function* ({ payload }: { payload: ICollectionExecutedDetails }) {
  const documentsLimitLeft: number = yield select((state) => state.profile.documentsLimitLeft);
  const documentsCountInCollection = payload.documents?.length ?? 0;
  const newCollectionStatus = payload.status;
  if (
    (newCollectionStatus === STATUS_COMPLETED && (
      documentsLimitLeft === null || documentsLimitLeft >= documentsCountInCollection)
    )
    || newCollectionStatus !== STATUS_COMPLETED
  ) {
    const collectionExecutedResponse: IResponseOfRequest<
      ICollectionExecutedDetails
    > = yield updateCollectionExecutedById(String(payload.id), payload);
    if (collectionExecutedResponse.data && collectionExecutedResponse.data.id) {
      if (documentsLimitLeft && collectionExecutedResponse.data.status === STATUS_COMPLETED) {
        yield put(setDocumentsLimitLeft(documentsLimitLeft - documentsCountInCollection));
      }

      const collectionsExecuted: ICollectionExecutedDetails[] = yield select(
        (state: RootStateType) => state.user.collectionsExecuted,
      );
      if (
        collectionExecutedResponse.data.documents?.every(
          (documentObject: ICollectionExecutedDocument) => documentObject.document.content_json,
        )
      ) {
        yield put(setCollectionExecutedDetails(collectionExecutedResponse.data));
      }
      const updatedCollectionsExecutedList = collectionsExecuted.map((collection) => {
        if (collection.id === collectionExecutedResponse.data.id) {
          return collectionExecutedResponse.data;
        }
        return collection;
      });
      yield put(setAllCollectionsExecuted(updatedCollectionsExecutedList));

      yield toast.success('Collection executed updated');
    } else {
      return toast.error('An error occurred while saving the collection executed');
    }
  }
};

const createCollectionExecutedPublicLink = function* ({ payload }: any) {
  const link: IResponseOfRequest<string> = yield saveCollectionExecutedPublicLink(payload);
  yield put(setCollectionExecutedPublicLink(link.data));
};

export default [
  takeLatest(GET_COLLECTION_EXECUTED_DETAILS, safe(getCollectionExecutedDetails)),
  takeLatest(UPDATE_COLLECTION_EXECUTED, safe(updateCollectionExecuted)),
  takeLatest(CREATE_COLLECTION_EXECUTED_PUBLIC_LINK, safe(createCollectionExecutedPublicLink)),
];