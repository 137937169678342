import { put, select, takeLatest } from 'redux-saga/effects';

import { STATUS_COMPLETED } from 'constants/documentStatuses';
import { SUCCESS_CODE } from 'constants/generalErrors';
import ResourcesStoreParts from 'constants/serverSideEvents';
import { getNewResource } from 'services/api/serverSideEvents';
import {
  SSE_UPDATE_RESOURCE, UPDATE_DOCUMENTS_COUNT,
} from 'store/actions/actionTypes';
import { updateResourceByType } from 'store/actions/serverSideEvents';
import { setDocumentsLimitLeft } from 'store/actions/userProfile';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { IDocumentDetails } from 'types/Documents';
import { Action } from 'types/redux';
import {
  GetNewResourcePropsType,
  ResourcesToUpdateType,
} from 'types/ServerSideEvents';

const getCorrectStorePartName = (resourceType: string) => {
  switch (resourceType) {
    case 'document':
      return ResourcesStoreParts.DOCUMENTS;
    case 'document-executed':
      return ResourcesStoreParts.DOCUMENTS_EXECUTED;
    case 'collection':
      return ResourcesStoreParts.COLLECTIONS;
    case 'collection-executed':
      return ResourcesStoreParts.COLLECTIONS_EXECUTED;
    default:
      return ResourcesStoreParts.DOCUMENTS;
  }
};

const updateResourceSaga = function* ({ payload }: Action<GetNewResourcePropsType>) {
  const documentsCount: number = yield select((state) => state.user.documentsCount);
  const correctStorePartName: ResourcesStoreParts = getCorrectStorePartName(payload.resourceType);
  const response: IResponseOfRequest<ResourcesToUpdateType[]> = yield getNewResource(payload);
  if (response && response.status === SUCCESS_CODE) {
    const targetResources: ResourcesToUpdateType[] = yield select((state) => state.user[correctStorePartName]);
    const newResource = targetResources.find((item) => item.id === Number(payload.resourceId));
    if (!newResource) {
      const doc = response.data[0] as IDocumentDetails;
      if (correctStorePartName === ResourcesStoreParts.DOCUMENTS_EXECUTED && doc.status === STATUS_COMPLETED) {
        const documentsLimitLeft: number = yield select((state) => state.profile.documentsLimitLeft);
        if (documentsLimitLeft) {
          yield put(setDocumentsLimitLeft(documentsLimitLeft - 1));
        }
      }
      yield put(updateResourceByType({
        resourceType: correctStorePartName,
        newResource: response.data,
      }));
      if ([ResourcesStoreParts.DOCUMENTS, ResourcesStoreParts.COLLECTIONS].includes(correctStorePartName)) {
        yield put({
          type: UPDATE_DOCUMENTS_COUNT,
          payload: documentsCount + 1,
        });
      }
    }
  }
};

export default [
  takeLatest(SSE_UPDATE_RESOURCE, safe(updateResourceSaga, false, true)),
];